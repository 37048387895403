import debug from 'debug';
import { nanoid } from 'nanoid';
import { merge } from 'ts-deepmerge';
import { onDocumentReady, fetchIntegration } from '@/util/utils';
import { WidgetController } from './controller/controller';
import '@/styles/web-client.scss';
let log = debug('moveo:web-client');
/**
 * Loads the integration configuration and merges it
 * with the user-supplied configuration,
 */
const loadWebClientConfig = async (userConfig) => {
    const widgetId = `moveo-${nanoid(5)}`;
    const clientUrl = window.location.href;
    log = log.extend(`:${widgetId}`);
    if (!(userConfig === null || userConfig === void 0 ? void 0 : userConfig.integrationId)) {
        throw new Error('integrationId is required.\n Call MoveoAI.init({ integrationId: YOUR_INTEGRATION_HERE })');
    }
    const widgetConfig = await fetchIntegration(userConfig.integrationId, userConfig.host);
    log('Loaded integration configuration:', widgetConfig);
    // Overwrite the widget configuration with the user configuration
    return Object.assign(Object.assign({}, merge(Object.assign({}, widgetConfig), userConfig)), { clientUrl,
        widgetId });
};
/**
 * Initializes the iframe, appends it to the body and return an instance.
 *
 */
export const init = async (userConfig) => {
    // 1. Wait for the Client DOM to be fully loaded
    await onDocumentReady();
    // 2. Load integration config and combine it with the user configuration
    // the window location and the device information.
    // This method prepares all the info we can gather from the client's web page
    const webClientConfig = await loadWebClientConfig(userConfig);
    log('Configuration:', webClientConfig);
    if (webClientConfig.widget_position === 'embed' && !webClientConfig.element) {
        throw new Error('element is not defined but required by embed widget');
    }
    // 3. Create a controller to manage the communication
    // between the customer and the web-client.
    // The messages will be filtered based on the widgetId
    const controller = new WidgetController({
        config: webClientConfig,
        version: userConfig.version || 'v1',
    });
    // 4. Initialize the controller which creates and attach the iframe along
    // with all the event listeners
    log('Initializing');
    const instance = await controller.init();
    log('Initialized');
    return instance;
};
