import debug from 'debug';
import { isbot } from 'isbot';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { UPDATE_ACTIVITY_INTERVAL_MS } from '@/config/constants';
import { IframeEventType, WebClientEventType, } from '@/ts';
import { userWayEvents } from '@/util/userway';
import { createIframeUrl, isWindowMobile, parseEvent, sleep, } from '@/util/utils';
/**
 * Communicates messages between the main page and the web-client iframe.
 * It generates a unique id to identify the iframe
 */
export class WidgetController {
    constructor({ config, version = 'v1' }) {
        this.detectUserway = () => {
            userWayEvents.forEach((event) => {
                document.addEventListener(`userway:feature_enabled_${event}`, () => {
                    var _a;
                    const value = (_a = localStorage.getItem(`userway-${event}`)) !== null && _a !== void 0 ? _a : '';
                    this.sendEventToIframe({
                        type: WebClientEventType.UserWayEnabled,
                        data: {
                            eventType: event,
                            value,
                        },
                    });
                });
                document.addEventListener(`userway:feature_disabled_${event}`, () => {
                    this.sendEventToIframe({
                        type: WebClientEventType.UserWayDisabled,
                        data: {
                            eventType: event,
                        },
                    });
                });
            });
        };
        this.initUserway = () => {
            const data = Object.assign({}, localStorage);
            Object.keys(data).forEach((key) => {
                const value = data[key] === 'true' ? data[key] : data[key];
                if (key.includes('userway-s') &&
                    (!isNaN(parseInt(value)) || value === 'true')) {
                    const id = key.split('-')[1];
                    if (value === '0') {
                        this.sendEventToIframe({
                            type: WebClientEventType.UserWayDisabled,
                            data: {
                                eventType: id,
                            },
                        });
                    }
                    else {
                        this.sendEventToIframe({
                            type: WebClientEventType.UserWayEnabled,
                            data: {
                                eventType: id,
                                value,
                            },
                        });
                    }
                }
            });
        };
        this.detectMobile = () => {
            this.sendEventToIframe({
                type: WebClientEventType.WindowResize,
                data: {
                    is_mobile: isWindowMobile(),
                    width: window.innerWidth,
                    height: window.innerHeight,
                },
            });
            this.isMobile = isWindowMobile();
        };
        this.trackActivity = () => {
            const updateLastActivity = () => {
                this.sendEventToIframe({
                    type: WebClientEventType.SessionActivity,
                    data: 'active',
                });
            };
            const trackLastActivity = throttle(updateLastActivity, UPDATE_ACTIVITY_INTERVAL_MS);
            window.onload = trackLastActivity;
            window.onmousemove = trackLastActivity;
            window.onmousedown = trackLastActivity; // catches touchscreen presses as well
            window.ontouchstart = trackLastActivity; // catches touchscreen swipes as well
            window.ontouchmove = trackLastActivity; // required by some devices
            window.onclick = trackLastActivity; // catches touchpad clicks as well
            window.onkeydown = trackLastActivity;
            window.addEventListener('scroll', trackLastActivity, true);
        };
        this.config = config;
        this.id = config.widgetId;
        this.initialized = false;
        this.log = debug(`moveo:web-client:${this.id}`);
        this.version = version;
        this.iframe = this.createIframe();
        this.isMobile = null;
    }
    createIframe() {
        const iframe = document.createElement('iframe');
        iframe.width = '1px';
        iframe.height = '1px';
        iframe.title = 'Moveo webchat';
        iframe.id = this.id;
        iframe.name = this.id;
        iframe.allow = 'geolocation';
        iframe.style.border = '0';
        iframe.classList.add('web-client-container');
        if (this.config.widget_position === 'embed') {
            iframe.classList.add('embed');
        }
        iframe.setAttribute('src', createIframeUrl(this.id, this.version));
        return iframe;
    }
    handleEvents(resolve, reject) {
        return (messageEvent) => {
            const event = parseEvent(this.id, messageEvent);
            if (!event) {
                return;
            }
            switch (event.type) {
                case IframeEventType.IframeLoaded: {
                    this.sendEventToIframe({
                        type: WebClientEventType.SetConfig,
                        data: this.config,
                    });
                    break;
                }
                case IframeEventType.IframeError: {
                    return reject(event.data);
                }
                case IframeEventType.UserWayInit: {
                    this.initUserway();
                    this.detectUserway();
                }
                case IframeEventType.WidgetLoaded: {
                    setTimeout(() => {
                        this.detectMobile();
                        resolve(this);
                    }, 0);
                    break;
                }
                case IframeEventType.WidgetError: {
                    this.notify(event);
                    break;
                }
                case IframeEventType.Opened: {
                    if (event.data.isFull || event.data.is_mobile) {
                        this.iframe.classList.add('full');
                    }
                    break;
                }
                case IframeEventType.Closed: {
                    if (event.data.isFull) {
                        this.iframe.classList.remove('full');
                    }
                    break;
                }
                case IframeEventType.Resize: {
                    this.iframe.style.width = event.data.width;
                    this.iframe.style.height = event.data.height;
                    this.iframe.style.left = event.data.left;
                    break;
                }
                default: {
                    this.log(`Unhandle event: ${event.type}`);
                }
            }
        };
    }
    init() {
        return new Promise((resolve, reject) => {
            var _a, _b;
            if (isbot(navigator.userAgent)) {
                throw new Error(`Widget ${this.id} was not initialized because user-agent is a bot`);
            }
            if (this.initialized) {
                throw new Error(`Widget ${this.id} was already initialized`);
            }
            window.addEventListener('message', this.handleEvents(resolve, reject));
            window.addEventListener('resize', debounce(this.detectMobile, 100));
            this.trackActivity();
            if (this.config.widget_position === 'embed') {
                let element = ((_a = this.config) === null || _a === void 0 ? void 0 : _a.element) || null;
                if (typeof element === 'string') {
                    element = document.getElementById(element);
                    if (!element) {
                        throw new Error(`Element with id: ${element} not found`);
                    }
                }
                // Add the widget to the provided element
                element === null || element === void 0 ? void 0 : element.appendChild(this.iframe);
            }
            else {
                (_b = window.document.querySelector('body')) === null || _b === void 0 ? void 0 : _b.appendChild(this.iframe);
            }
            this.initialized = true;
            this.detectMobile();
        });
    }
    /**
     * Sends a message to the iframe using the iframe id as prefix.
     */
    sendEventToIframe(event) {
        var _a;
        if (this.iframe && this.iframe.contentWindow) {
            this.log(`Event to iframe: ${event.type}`, event);
            if (event.type === WebClientEventType.SetConfig && ((_a = event.data) === null || _a === void 0 ? void 0 : _a.element)) {
                delete event.data.element;
            }
            // Add the `widgetId` to the event type
            this.iframe.contentWindow.postMessage(Object.assign(Object.assign({}, event), { type: `${this.id}${event.type}` }), '*');
        }
        else {
            this.log('Error: Cannot find iframe to send event');
        }
    }
    notify(event) {
        this.log(`notify ${event.type}:`, event);
    }
    notifyParent(eventType, callback) {
        window.addEventListener('message', (messageEvent) => {
            const event = parseEvent(this.id, messageEvent);
            if (!event) {
                return;
            }
            this.log(`Event from iframe: ${event.type}`, event);
            if (event.type === eventType) {
                if ('data' in event) {
                    callback(event.data);
                }
                else {
                    callback();
                }
            }
        });
    }
    // --------------------------------
    // Start the instance methods here.
    // --------------------------------
    openWindow() {
        this.sendEventToIframe({
            type: WebClientEventType.Open,
        });
    }
    async destroy() {
        this.closeConversation({ hide: true });
        await sleep(4000);
        // Remove the iframe from the DOM
        const element = document.getElementById(this.id);
        if (element) {
            element.remove();
            this.initialized = false;
        }
    }
    closeConversation({ hide }) {
        this.sendEventToIframe({
            type: WebClientEventType.CloseConversation,
            data: { hide },
        });
    }
    closeWindow() {
        this.sendEventToIframe({ type: WebClientEventType.Close });
    }
    sendMessage(message) {
        this.sendEventToIframe({
            type: WebClientEventType.SendMessage,
            data: message,
        });
    }
    updateContext(data) {
        this.sendEventToIframe({
            type: WebClientEventType.UpdateContext,
            data,
        });
    }
    setCSSVariables(data) {
        this.sendEventToIframe({
            type: WebClientEventType.SetCssVariables,
            data,
        });
    }
    setLocale(locale) {
        this.sendEventToIframe({
            type: WebClientEventType.SetLocale,
            data: locale,
        });
    }
    onSessionCreated(callback) {
        this.notifyParent(IframeEventType.SessionCreated, callback);
    }
    onSessionClosed(callback) {
        this.notifyParent(IframeEventType.SessionClosed, callback);
    }
    onConversationClosed(callback) {
        this.notifyParent(IframeEventType.ConversationClosed, callback);
    }
    onSessionReconnected(callback) {
        this.notifyParent(IframeEventType.SessionReconnected, callback);
    }
    onNoUnreadMessages(callback) {
        this.notifyParent(IframeEventType.ConversationNoUnreadMessages, callback);
    }
    onUnreadMessages(callback) {
        this.notifyParent(IframeEventType.ConversationUnreadMessages, callback);
    }
    onLinkClicked(callback) {
        this.notifyParent(IframeEventType.LinkClicked, callback);
    }
}
