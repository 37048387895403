/**
 * Mapping of country codes to their respective language names.
 * Provides a record of language names based on ISO country codes.
 */
export const languageByCountryCode = {
    en: 'English',
    el: 'Ελληνικά',
    es: 'Español',
    pt: 'Português',
    'pt-br': 'Português brasileiro',
    it: 'Italiano',
    fr: 'Français',
    da: 'Dansk',
    de: 'Deutsch',
    ro: 'Română',
    bg: 'Български',
    cs: 'Čeština',
    pl: 'Polski',
    id: 'Bahasa Indonesia',
    me: 'Montenegrin',
    nl: 'Nederlands',
    'nl-be': 'Nederlands (België)',
    sr: 'Српски',
    sv: 'Svenska',
    ru: 'Русский',
    sq: 'Shqip',
    ar: 'العربية',
    ja: '日本語',
    zh: '中文',
    tr: 'Türkçe',
};
/**
 * URL of the next version of the web client.
 */
export const WEB_CLIENT_NEXT_URL = 'https://web-client.dev.moveo.ai';
/**
 * URL of the production web client.
 */
export const WEB_CLIENT_URL = 'https://web-client.moveo.ai';
/**
 * Timeout duration in milliseconds for modals.
 * Specifies the time duration for modal timeouts.
 * The value is 400 milliseconds.
 */
export const MODAL_TIMEOUT = 400;
/**
 * Minimum number of messages required to show a survey.
 * Specifies the minimum number of messages required to trigger a survey display.
 * The value is 3.
 */
export const MINIMUM_MESSAGES_TO_SHOW_SURVEY = 3;
export const LOCAL_EVENT = 'local-storage';
export const STORAGE_EVENT = 'storage';
/**
 * The size in pixels of the custom launcher image.
 * The value is 72 pixels.
 **/
export const CUSTOM_LAUNCHER_SIZE_PX = '72px';
/**
 * The size in pixels of the bubble image.
 * The value is 60 pixels.
 **/
export const BUBBLE_SIZE_PX = '60px';
/**
 * The maximum number of minutes that user can be inactive before we consider
 * them to reconnect to the server.
 * The value is 30 minutes.
 */
export const MAX_INACTIVITY_TO_RECONNECT_MS = 30 * 60 * 1000; // 30 minutes
/**
 * The interval, in milliseconds, at which the inactivity is checked.
 * The value is 5 seconds.
 * */
export const CHECK_INACTIVITY_INTERVAL_MS = 5 * 1000; // 5 seconds
/**
 * The interval to use when updating the visitor activity in local storage
 * The value is 3 seconds.
 * */
export const UPDATE_ACTIVITY_INTERVAL_MS = 3 * 1000; // 3 seconds
/**
 * The duration, in milliseconds, of inactivity timeout.
 * This variable represents the amount of time that can elapse without any user activity
 * before considering the user as inactive.
 * The value is 10 minutes.
 */
export const INACTIVITY_TIMEOUT_MS = 10 * 60 * 1000;
/**
 * The maximum duration, in milliseconds, that the compose indicator
 * will be displayed
 * The value is 20 seconds.
 */
export const MAX_TYPING_INDICATOR_MS = 20000;
/**
 * The maximum duration, in milliseconds, that can elapse before
 * considering the user as having stopped typing.
 * The value is 1.1 seconds.
 */
export const MAX_TYPING_DURATION_MS = 1100;
/**
 * The maximum length, in characters for user messages.
 * The value is 280 characters.
 */
export const MAX_CHARS = 280;
/**
 * The interval, in milliseconds, at which the last activity is sent to the server.
 * The value is 10 seconds.
 */
export const DEFAULT_UPDATE_INTERVAL_MS = 10 * 1000; // 10 seconds
/**
 * The interval, in milliseconds, at which the session id is checked in
 * local storage. This is used to detect if the user create another session
 * in another tab.
 * The value is 5 seconds.
 **/
export const SESSION_ID_CHECK_INTERVAL_MS = 5000; // 5 seconds
export const isArabic = (text) => {
    const arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text) ? 'rtl' : 'ltr';
};
export const NUMBER_OF_ACCEPTED_FILES = 4;
export const ONE_MB = 1024 * 1024;
export const MAX_FILE_SIZE = 4 * ONE_MB;
export const ACCEPTED_FILE_TYPES = {
    'application/pdf': ['.pdf'],
    'text/plain': ['.txt', '.csv'],
    'application/msword': ['.doc', '.docx'],
    'application/vnd.ms-excel': ['.xls', '.xlsx'],
    'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/gif': ['.gif'],
};
